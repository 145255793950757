import type { RouteRecordRaw } from 'vue-router';

export const routes: RouteRecordRaw[] = [
  {
    path: 'contract',
    name: 'contract.index',
    component: () => import('./views/pages/Index.vue'),
    children: [
      {
        path: 'create',
        name: 'contract.create',
        component: () => import('./views/modals/Create.vue'),
        meta: {
          delay: true,
          permission: 'contract.store',
        },
      },
      {
        path: ':contractId/edit',
        name: 'contract.edit',
        component: () => import('./views/modals/Edit.vue'),
        meta: {
          delay: true,
          permission: 'contract.update',
        },
      },
    ],
    meta: {
      title: 'Systeem instellingen',
      permission: 'contract.index',
    },
  },
];
