<script setup lang="ts">
import { useUserStore } from '@libero/hooks/store/useUserStore';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import Container from '@libero/ui-framework/Container/Container.vue';
import SecondaryNav from '@libero/ui-framework/SecondaryNav/SecondaryNav.vue';
import SecondaryNavItem from '@libero/ui-framework/SecondaryNav/SecondaryNavItem.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { hasPermission } = useUserStore();
</script>

<template>
  <Container hasOffset>
    <Cluster flex="1" alignItems="stretch">
      <SecondaryNav hasOffset>
        <SecondaryNavItem
          :to="{ name: 'entity-type.index' }"
          :isDisabled="!hasPermission('entity-type.index')"
        >
          {{ t('entity-type.index') }}
        </SecondaryNavItem>

        <SecondaryNavItem
          :to="{ name: 'attribute-type.index' }"
          :isDisabled="!hasPermission('attribute-type.index')"
        >
          {{ t('attribute-type.index') }}
        </SecondaryNavItem>

        <SecondaryNavItem
          :to="{ name: 'product.index' }"
          :isDisabled="!hasPermission('product.index')"
        >
          {{ t('product.index') }}
        </SecondaryNavItem>

        <SecondaryNavItem
          :to="{ name: 'export.index' }"
          :isDisabled="!hasPermission('export.index')"
        >
          {{ t('export.index') }}
        </SecondaryNavItem>
      </SecondaryNav>

      <Stack>
        <RouterView />
      </Stack>
    </Cluster>
  </Container>
</template>
