import Fuse from 'fuse.js';
import { computed, isRef, MaybeRef, Ref } from 'vue';

export const useCollectionFilter = <T>(
  optionsRef: MaybeRef<T[] | undefined>,
  searchTerm: Ref<string | null>,
  keys: string[],
): Ref<T[]> => {
  const options = computed(() => (isRef(optionsRef) ? optionsRef.value : optionsRef));

  const fuse = computed(
    () => new Fuse(options.value || [], { keys, threshold: 0.1, distance: 500 }),
  );

  const filteredOptions = computed(() =>
    searchTerm.value
      ? fuse.value.search(searchTerm.value).map((result) => result.item)
      : options.value || [],
  );

  return filteredOptions;
};
