import { generateScopedName, generateScopedPath } from '@libero/utilities/generic-routing';
import { RouteRecordRaw } from 'vue-router';

export const generateOrganisationRoutes = (key: string): RouteRecordRaw[] => [
  {
    path: generateScopedPath('create', 'organisation', key),
    name: generateScopedName('organisation.create', 'organisation', key),
    component: () => import('./views/modals/Create.vue'),
    props: { scope: key },
    meta: {
      delay: true,
      permission: 'organisation.store',
    },
  },
  {
    path: generateScopedPath(':id', 'organisation', key),
    component: () => import('./views/modals/Show.vue'),
    props: { scope: key },
    children: [
      {
        path: 'properties',
        name: generateScopedName('organisation.show', 'organisation', key),
        component: () => import('./views/tabs/Properties.vue'),
      },
      {
        path: 'properties/edit',
        name: generateScopedName('organisation.edit', 'organisation', key),
        component: () => import('./views/tabs/PropertiesEdit.vue'),
      },
      {
        path: 'assignments',
        name: generateScopedName('organisation.assignment', 'organisation', key),
        component: () => import('./views/tabs/Assignments.vue'),
      },
      {
        path: 'users',
        name: generateScopedName('organisation.users', 'organisation', key),
        component: () => import('./views/tabs/Users.vue'),
      },
      {
        path: 'log',
        name: generateScopedName('organisation.log', 'organisation', key),
        component: () => import('./views/tabs/Log.vue'),
      },
      {
        path: '',
        redirect: { name: generateScopedName('organisation.show', 'organisation', key) },
      },
    ],
    meta: {
      delay: true,
      permission: 'organisation.show',
    },
  },
];
