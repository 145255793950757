import { router } from '@libero/cityview/router';
import { generateScopedName, generateScopedPath } from '@libero/utilities/generic-routing';
import { RouteRecordRaw } from 'vue-router';

const generateTicketTabRoutes = (prefix: string, key: string): RouteRecordRaw[] => [
  {
    path: 'job-sheet',
    name: generateScopedName(`${prefix}.job-sheet`, 'ticket', key),
    component: () => import('./views/tabs/JobSheet.vue'),
  },
  {
    path: 'map',
    name: generateScopedName(`${prefix}.map`, 'ticket', key),
    component: () => import('./views/tabs/Map.vue'),
  },
  {
    path: 'relations',
    name: generateScopedName(`${prefix}.relations`, 'ticket', key),
    component: () => import('./views/tabs/Relations.vue'),
  },
  {
    path: 'documents',
    name: generateScopedName(`${prefix}.documents`, 'ticket', key),
    component: () => import('./views/tabs/Documents.vue'),
  },
  {
    path: 'tickets',
    name: generateScopedName(`${prefix}.tickets`, 'ticket', key),
    component: () => import('./views/tabs/Tickets.vue'),
  },
  {
    path: 'containers',
    name: generateScopedName(`${prefix}.containers`, 'ticket', key),
    component: () => import('./views/tabs/Containers.vue'),
  },
  {
    path: 'notes',
    name: generateScopedName(`${prefix}.notes`, 'ticket', key),
    component: () => import('./views/tabs/Notes.vue'),
  },
  {
    path: 'log',
    name: generateScopedName(`${prefix}.log`, 'ticket', key),
    component: () => import('./views/tabs/Log.vue'),
  },
];

export const generateTicketRoutes = (key: string): RouteRecordRaw[] => [
  {
    path: generateScopedPath('create', 'ticket', key),
    name: generateScopedName('ticket.create', 'ticket', key),
    component: () => import('./views/modals/Create.vue'),
    props: { scope: key },
    meta: {
      delay: true,
      permission: 'ticket.store',
    },
  },
  {
    path: generateScopedPath(':id', 'ticket', key),
    component: () => import('./views/modals/Show.vue'),
    props: { scope: key },
    children: [
      {
        path: 'properties',
        component: () => import('./views/tabs/Properties.vue'),
        children: generateTicketTabRoutes('ticket.show', key),
      },
      {
        path: 'properties/edit',
        component: () => import('./views/tabs/PropertiesEdit.vue'),
        children: generateTicketTabRoutes('ticket.edit', key),
      },
      {
        name: generateScopedName('ticket.show', 'ticket', key),
        path: '',
        redirect: () => {
          const fromName = router.currentRoute.value.name as string;
          const tab = fromName?.startsWith('ticket.edit') ? fromName.split('.').pop() : 'job-sheet';
          return { name: generateScopedName(`ticket.show.${tab}`, 'ticket', key) };
        },
      },
      {
        name: generateScopedName('ticket.edit', 'ticket', key),
        path: '',
        redirect: () => {
          const fromName = router.currentRoute.value.name as string;
          const tab = fromName?.startsWith('ticket.show') ? fromName.split('.').pop() : 'job-sheet';
          return { name: generateScopedName(`ticket.edit.${tab}`, 'ticket', key) };
        },
      },
      {
        path: '',
        redirect: { name: generateScopedName('ticket.show', 'ticket', key) },
      },
    ],
    meta: {
      delay: true,
      permission: 'ticket.show',
    },
  },
];
