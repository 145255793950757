import { echo, UnsubscribeCallback } from '@libero/utilities/echo-client';

import type { Progress } from './types';

type SubscribeCallback = (progress: Progress) => void;

const subscribe = (userId: string, callback: SubscribeCallback): UnsubscribeCallback => {
  const channel = echo
    .private(`App.Models.User.${userId}`)
    .listen('ContainerReplicationProgress', callback);

  return () => channel.stopListening('ContainerReplicationProgress');
};

export const progressApi = {
  name: 'progress',
  subscribe,
};
