import { Attribute } from '@libero/api-client/attribute/types';
import { Container } from '@libero/api-client/container/types';
import { Entity } from '@libero/api-client/entity/types';

export const getEntityAttribute = (entity: Entity, key: string): Attribute | undefined => {
  return entity.attributes
    ?.filter((attribute) => attribute.attribute_type.key === key)
    .filter((attribute) => !!attribute.data?.value)
    .at(0);
};

export const getEntityAttributeValue = (entity: Entity, key: string): string | undefined => {
  const attribute = getEntityAttribute(entity, key);
  return attribute?.data.label || attribute?.data.value?.toString();
};

export const getContainerAttribute = (
  container: Container,
  key: string,
  isRoot = false,
): Attribute | undefined => {
  return container.entities
    ?.filter((entity) => (isRoot ? entity.parent_id === null : true))
    .flatMap((entity) => getEntityAttribute(entity, key))
    .filter(Boolean)
    .at(0);
};

export const getContainerAttributeValue = (
  container: Container,
  key: string,
  isRoot = false,
): string | undefined => {
  return container.entities
    ?.filter((entity) => (isRoot ? entity.parent_id === null : true))
    .flatMap((entity) => getEntityAttributeValue(entity, key))
    .join(', ');
};
