import ContractLayout from '@libero/cityview/modules/contract/views/layouts/ContractLayout/ContractLayout.vue';
import { RouteRecordRaw } from 'vue-router';

import SystemSettingsLayout from './views/layouts/SystemSettingsLayout/SystemSettingsLayout.vue';

const children = Object.values(
  import.meta.glob(`../(user|organisation|role|contract|ticket-subject|cause)/routes.ts`, {
    eager: true,
  }) as Record<string, { routes: RouteRecordRaw[] }>,
)
  .map((routes) => routes.routes)
  .flat();

const contractChildren = Object.values(
  import.meta.glob(`../(contract-item|price-list|term-sheet|lead-time)/routes.ts`, {
    eager: true,
  }) as Record<string, { routes: RouteRecordRaw[] }>,
)
  .map((routes) => routes.routes)
  .flat();

export const routes: RouteRecordRaw[] = [
  {
    path: '/system-settings',
    component: SystemSettingsLayout,
    children: [
      ...children,
      {
        path: '',
        redirect: { name: 'user.index' },
      },
    ],
  },
  {
    path: '/system-settings/contract/:contractId',
    component: ContractLayout,
    children: [
      ...contractChildren,
      {
        path: 'log',
        name: 'contract.log',
        component: () => import('../contract/views/pages/Log.vue'),
        meta: {
          title: 'Contract log',
          permission: 'contract.show',
        },
      },
      {
        name: 'contract.show',
        path: '',
        redirect: { name: 'contract-item.index' },
      },
    ],
  },
];
