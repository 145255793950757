<script setup lang="ts">
import type { Article } from '@libero/types/Article';
import Block from '@libero/ui-framework/Block/Block.vue';
import Clickable from '@libero/ui-framework/Clickable/Clickable.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';

interface Props {
  article: Article;
  onSelect: (id: number) => void;
}

const props = defineProps<Props>();

const handleSelect = () => {
  props.onSelect(props.article.id);
};
</script>

<template>
  <Clickable class="help-list-item" :onClick="handleSelect">
    <Block :horizontalSpacing="0.75" borderRadius="sm" hasHover>
      <Stack :gap="0.5">
        <Typography tag="h3" type="heading" bold>{{ article.title }}</Typography>
        <Typography>{{ article.summary }}</Typography>
      </Stack>
    </Block>
  </Clickable>
</template>
