import { generateScopedName, generateScopedPath } from '@libero/utilities/generic-routing';
import { RouteRecordRaw } from 'vue-router';

export const generateFolderRoutes = (key: string): RouteRecordRaw[] => [
  {
    path: generateScopedPath('create', 'folder', key),
    name: generateScopedName('folder.create', 'folder', key),
    component: () => import('./views/modals/Create.vue'),
    props: { scope: key },
    meta: {
      delay: true,
      permission: 'document.store',
    },
  },
  {
    path: generateScopedPath(':id', 'folder', key),
    component: () => import('./views/modals/Show.vue'),
    props: { scope: key },
    children: [
      {
        path: 'properties',
        name: generateScopedName('folder.show', 'folder', key),
        component: () => import('./views/tabs/Properties.vue'),
      },
      {
        path: 'properties/edit',
        name: generateScopedName('folder.edit', 'folder', key),
        component: () => import('./views/tabs/PropertiesEdit.vue'),
      },
      {
        path: 'containers',
        name: generateScopedName('folder.containers', 'folder', key),
        component: () => import('./views/tabs/Containers.vue'),
      },
      {
        path: 'log',
        name: generateScopedName('folder.log', 'folder', key),
        component: () => import('./views/tabs/Log.vue'),
      },
      {
        path: '',
        redirect: { name: generateScopedName('folder.show', 'folder', key) },
      },
    ],
    meta: {
      delay: true,
      permission: 'document.show',
    },
  },
];
