<script setup lang="ts">
import { MagnifyingGlassIcon } from '@heroicons/vue/24/solid';
import HelpSupport from '@libero/cityview/modules/help/components/HelpSupport/HelpSupport.vue';
import Support from '@libero/cityview/modules/support/views/modals/Support.vue';
import { useCollectionFilter } from '@libero/hooks/useCollectionFilter';
import Drawer from '@libero/ui-framework/Drawer/Drawer.vue';
import DrawerSection from '@libero/ui-framework/Drawer/DrawerSection.vue';
import Input from '@libero/ui-framework/Input/Input.vue';
import Link from '@libero/ui-framework/Link/Link.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';
import Typography from '@libero/ui-framework/Typography/Typography.vue';
import { articles } from 'virtual:nova-articles';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';

import HelpListItem from './HelpListItem.vue';

interface Props {
  open: boolean;
  hasOffset: boolean;
  onSelect: (id: number) => void;
  onClose: () => void;
}

defineProps<Props>();

const { t } = useI18n();

const searchTerm = ref<string | null>(null);

const onUpdateSearchTerm = (value: string | null) => {
  searchTerm.value = value;
};

const filteredArticles = useCollectionFilter(articles, searchTerm, ['title', 'summary', 'content']);
</script>

<template>
  <Drawer :isOpen="open" :hasOffset="hasOffset" width="30rem" :onClose="onClose">
    <Stack :gap="0" flex="1">
      <DrawerSection spacing="lg">
        <Stack :gap="0.5">
          <Typography type="heading" bold>
            {{ t('help.help') }}
          </Typography>

          <Input
            id="search-term"
            name="search-term"
            :value="searchTerm"
            :placeholder="t('help.search-articles')"
            isClearable
            :onUpdate="onUpdateSearchTerm"
          >
            <template #icon>
              <MagnifyingGlassIcon />
            </template>
          </Input>
        </Stack>
      </DrawerSection>

      <Stack flex="1">
        <DrawerSection flex="1" spacing="md" isScrollable>
          <Stack :gap="0">
            <HelpListItem
              v-for="article in filteredArticles"
              :key="article.id"
              :article="article"
              :onSelect="onSelect"
            />

            <HelpSupport />
          </Stack>
        </DrawerSection>
      </Stack>

      <DrawerSection spacing="lg">
        <Stack :gap="0.75">
          <Support v-slot="{ openModal }">
            <Link :onClick="openModal">{{ t('help.contact-support') }}</Link>
          </Support>
        </Stack>
      </DrawerSection>
    </Stack>
  </Drawer>
</template>
