import { generateScopedName, generateScopedPath } from '@libero/utilities/generic-routing';
import { RouteRecordRaw } from 'vue-router';

export const generateFileRoutes = (key: string): RouteRecordRaw[] => [
  {
    path: generateScopedPath('create', 'file', key),
    name: generateScopedName('file.create', 'file', key),
    component: () => import('./views/modals/Create.vue'),
    props: { scope: key },
    meta: {
      delay: true,
      permission: 'document.store',
    },
  },
  {
    path: generateScopedPath('create/multiple', 'file', key),
    name: generateScopedName('file.create.multiple', 'file', key),
    component: () => import('./views/modals/CreateMultiple.vue'),
    props: { scope: key },
    meta: {
      delay: true,
      permission: 'document.store',
    },
  },
  {
    path: generateScopedPath(':id', 'file', key),
    component: () => import('./views/modals/Show.vue'),
    props: { scope: key },
    children: [
      {
        path: 'properties',
        name: generateScopedName('file.show', 'file', key),
        component: () => import('./views/tabs/Properties.vue'),
      },
      {
        path: 'properties/edit',
        name: generateScopedName('file.edit', 'file', key),
        component: () => import('./views/tabs/PropertiesEdit.vue'),
      },
      {
        path: 'containers',
        name: generateScopedName('file.containers', 'file', key),
        component: () => import('./views/tabs/Containers.vue'),
      },
      {
        path: 'tickets',
        name: generateScopedName('file.tickets', 'file', key),
        component: () => import('./views/tabs/Tickets.vue'),
      },
      {
        path: 'versions',
        name: generateScopedName('file.versions', 'file', key),
        component: () => import('./views/tabs/Versions.vue'),
      },
      {
        path: 'log',
        name: generateScopedName('file.log', 'file', key),
        component: () => import('./views/tabs/Log.vue'),
      },
      {
        path: '',
        redirect: { name: generateScopedName('file.show', 'file', key) },
      },
    ],
    meta: {
      delay: true,
      permission: 'document.show',
    },
  },
];
