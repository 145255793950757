<script setup lang="ts">
import HelpArticle from '@libero/cityview/modules/help/components/HelpArticle/HelpArticle.vue';
import HelpList from '@libero/cityview/modules/help/components/HelpList/HelpList.vue';
import { articles } from 'virtual:nova-articles';
import { computed, ref } from 'vue';

const active = ref<number>();
const isOpen = ref(false);
const isOpenArticle = ref(false);

const toggleOpen = () => {
  isOpen.value = !isOpen.value;
};

const handleOpenArticle = (id: number) => {
  active.value = id;
  isOpenArticle.value = true;
};

const handleCloseArticle = () => {
  isOpenArticle.value = false;
};

const article = computed(() => articles.find((item) => item.id === active.value));
</script>

<template>
  <slot :openDrawer="toggleOpen" />

  <HelpList
    :open="isOpen"
    :hasOffset="isOpenArticle"
    :onSelect="handleOpenArticle"
    :onClose="toggleOpen"
  />

  <HelpArticle :open="isOpenArticle" :article="article" :onClose="handleCloseArticle" />
</template>
