import { generateScopedName, generateScopedPath } from '@libero/utilities/generic-routing';
import { RouteRecordRaw } from 'vue-router';

export const generateUserRoutes = (key: string): RouteRecordRaw[] => [
  {
    path: generateScopedPath('create', 'user', key),
    name: generateScopedName('user.create', 'user', key),
    component: () => import('./views/modals/Create.vue'),
    props: { scope: key },
    meta: {
      delay: true,
      permission: 'user.store',
    },
  },
  {
    path: generateScopedPath(':id', 'user', key),
    component: () => import('./views/modals/Show.vue'),
    props: { scope: key },
    children: [
      {
        path: 'properties',
        name: generateScopedName('user.show', 'user', key),
        component: () => import('./views/tabs/Properties.vue'),
      },
      {
        path: 'properties/edit',
        name: generateScopedName('user.edit', 'user', key),
        component: () => import('./views/tabs/PropertiesEdit.vue'),
      },
      {
        path: 'assignments',
        name: generateScopedName('user.assignments', 'user', key),
        component: () => import('./views/tabs/Assignments.vue'),
      },
      {
        path: 'log',
        name: generateScopedName('user.log', 'user', key),
        component: () => import('./views/tabs/Log.vue'),
      },
      {
        path: '',
        redirect: { name: generateScopedName('user.show', 'user', key) },
      },
    ],
    meta: {
      delay: true,
      permission: 'user.show',
    },
  },
];
