<script setup lang="ts">
import { supportApi } from '@libero/api-client/support/api';
import type { EmailSupportRequest } from '@libero/api-client/support/types';
import { useForm } from '@libero/hooks/useForm';
import Media from '@libero/organisms/Media/Media.vue';
import Button from '@libero/ui-framework/Button/Button.vue';
import Cluster from '@libero/ui-framework/Cluster/Cluster.vue';
import DatePicker from '@libero/ui-framework/DatePicker/DatePicker.vue';
import Form from '@libero/ui-framework/Form/Form.vue';
import FormLayout from '@libero/ui-framework/FormLayout/FormLayout.vue';
import InputGroup from '@libero/ui-framework/InputGroup/InputGroup.vue';
import Label from '@libero/ui-framework/Label/Label.vue';
import ModalActions from '@libero/ui-framework/ModalActions/ModalActions.vue';
import Select from '@libero/ui-framework/Select/Select.vue';
import SubLabel from '@libero/ui-framework/SubLabel/SubLabel.vue';
import Textarea from '@libero/ui-framework/Textarea/Textarea.vue';
import { useMutation } from '@tanstack/vue-query';
import { message } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';

interface Props {
  onClose: () => void;
}

const props = defineProps<Props>();

const { t } = useI18n();

const form = useForm<EmailSupportRequest>({
  subject: null,
  message: null,
  media: [],
  deadline_at: null,
});

const { mutateAsync, isPending } = useMutation({
  mutationFn: supportApi.emailSupport,
  onSuccess: () => {
    message.success(t('support.sent'));
    props.onClose();
  },
});

const handleSubmit = () => {
  form.submit(mutateAsync);
};
</script>

<template>
  <Form v-bind="form.registerForm(handleSubmit)">
    <FormLayout>
      <InputGroup>
        <Label required for="subject" :value="t('support.subject')" />

        <Select
          :placeholder="t('support.choose-subject')"
          :options="
            ['question', 'reaction', 'bug', 'suggestion', 'import'].map((name) => ({
              value: t(`support.subject-options.${name}`),
              label: t(`support.subject-options.${name}`),
            }))
          "
          v-bind="form.register('subject')"
        />
      </InputGroup>

      <InputGroup>
        <Label required for="description" :value="t('support.description')" />
        <Textarea v-bind="form.register('message')" />
      </InputGroup>

      <InputGroup v-if="form.values.subject === t('support.import')">
        <Label for="deadline_at" :value="t('support.deadline-at')" />
        <DatePicker v-bind="form.register('deadline_at')" />
      </InputGroup>

      <InputGroup>
        <Label for="media" :value="t('support.files')" />
        <SubLabel :value="t('support.files-note')" />
        <Media v-bind="form.register('media')" />
      </InputGroup>
    </FormLayout>

    <ModalActions>
      <Cluster justifyContent="end">
        <Button appearance="outline" :onClick="onClose">
          {{ t('cancel') }}
        </Button>

        <Button :label="t('send')" type="submit" :isLoading="isPending" />
      </Cluster>
    </ModalActions>
  </Form>
</template>
